<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Documentación (puedes agregar uno o más documentos)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-autocomplete v-model="tipoDocumentoSeleccionado" label="Tipo documento*" outlined
                        :items="catalogoTipoDocumentos" item-text="nombre" item-value="id" class="rounded-lg" required
                        :error-messages="tipoDocumentoSeleccionadoErrors"
                        @blur="$v.tipoDocumentoSeleccionado.$touch()"
                        return-object
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-text-field v-model="numeroDocumentoIngresado" label="Número de documento*" outlined
                      :disabled="deshabilitarNumeroDocumento" v-facade="documento_mask" class="rounded-lg"
                      color="#697182"
                      required
                      :maxlength="maxCaracteresNumeroDocumento" counter :error-messages="NumeroDocumentoErrors"
                      @blur="$v.numeroDocumentoIngresado.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-file-input accept="image/png, image/jpeg, image/bmp, application/pdf, image/jpg" prepend-icon=""
                      append-icon="mdi-tray-arrow-down" label="Adjuntar documento"
                      outlined @click:clear="eliminarFotoDocumento()" :error-messages="fotoDocumentoErrors"
                      v-model="fotoDocumentoIngresado" @blur="$v.fotoDocumentoIngresado.$touch()"
                      hint="Formatos permitidos PNG, JPEG, PDF, JPG y peso máx. 2MB"
                      persistent-hint
                      truncate-length="15">
          <!-- :error-messages="fotoPerfilError" --></v-file-input>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
        <v-btn @click="agregarDocumento" rounded class="ml-5 text-capitalize"
               style="background-color: #1C1E4D; color: white;">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template>
          <v-dialog
              v-model="dialogFoto"
              max-width="400"
          >
            <v-card>
              <v-card-title class="text-subtitle-1 text-break pb-6">
                Actualizar fotografía de {{ dialogNombre }}
              </v-card-title>
              <v-card-text>
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, application/pdf, image/jpg"
                  prepend-icon=""
                  append-icon="mdi-tray-arrow-down"
                  label="Adjuntar documento"
                  outlined
                  @click:clear="eliminarFotoDocumento"
                  :error-messages="errorDocumentoAdjunto"
                  v-model="fotoActualiza"
                  truncate-length="15"
                />
              </v-card-text>
              <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn
                    color=" darken-1"
                    text
                    class="text-capitalize"
                    @click="dialogFoto = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
                    text
                    @click="actualizarDocument(dialogId)"
                >
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <v-data-table :items="listadoDocumentos" :headers="headerDocumentos" no-data-text="No hay datos disponibles"
          class="elevation-1 header-table mb-10" hide-default-footer>
          <template #[`item.tipo_documento`]="{ item }">
            {{ item.TipoDocumento.nombre }}
          </template>
          <template #[`item.numero_documento`]="{ item }">
            {{ item.numero != 'null' ? item.numero : '-'}}
          </template>
          <template v-slot:item.acciones="{ item }">
            <span 
              v-if="!item.ruta_archivo && item.TipoDocumento.nombre === 'Documento Único de Identidad' " 
              @click="dialogOpen(item.id, item.TipoDocumento.nombre)" 
              style="cursor: pointer"
            >
              <v-icon>
                mdi-file-document-edit-outline
              </v-icon>
              Actualizar
            </span>
            <v-tooltip left v-else-if="item.ruta_archivo !== '' && item.ruta_archivo !== null">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  @click="showDocument('T', item.TipoDocumento.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  icon 
                  @click="eliminarDocumento(item.id)" 
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Medicamento (puedes agregar uno o más medicamentos)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Agregar medicamento*" outlined :error-messages="errorMedicamento"
          v-model="nombreMedicamentoIngresado" @blur="$v.nombreMedicamentoIngresado.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Especifique*" outlined :error-messages="errorEspecifiqueMedicamento" @blur="$v.especifiqueMedicamentoIngresado.$touch()"
                      v-model="especifiqueMedicamentoIngresado"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
          @click="agregarMedicamento">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoMedicamentos" :headers="headerMedicamentos" no-data-text="No hay datos disponibles"
          class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarAntecedente(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Alergias (puedes agregar una o más alergias)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Agregar alergia*" outlined :error-messages="errorAlergia"
          v-model="nombreAlergiaIngresado" @blur="$v.nombreAlergiaIngresado.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Especifique*" outlined :error-messages="errorEspecifiqueAlergia" @blur="$v.especifiqueAlergiaIngresado.$touch()"
          v-model="especifiqueAlergiaIngresado"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
          @click="agregarAlergia">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoAlergias" :headers="headerAlergias" no-data-text="No hay datos disponibles"
          class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarAntecedente(item)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Enfermedades (puedes agregar una o más enfermedades)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Agregar enfermedad*" outlined :error-messages="errorEnfermedad"
          v-model="nombreEnfermedadIngresado" @blur="$v.nombreEnfermedadIngresado.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Especifique*" outlined :error-messages="errorEspecifiqueEnfermedad" @blur="$v.especifiqueEnfermedadIngresada.$touch()"
          v-model="especifiqueEnfermedadIngresada"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarEnfermedad">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoEnfermedades" :headers="headerEnfermedades" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarAntecedente(item)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Discapacidades (puedes agregar una o más discapacidades)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-autocomplete label="Discapacidad*" outlined :items="catalogoDiscapacidades" item-text="nombre"
                        item-value="id"
                        class="rounded-lg" required :error-messages="errorDiscapacidad" @blur="$v.discapacidadIngresada.$touch()"
                        v-model="discapacidadIngresada"></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-text-field label="Especifique*" outlined :error-messages="errorEspecifiqueDiscapacidad" @blur="$v.especifiqueDiscapacidadIngresado.$touch()"
                      v-model="especifiqueDiscapacidadIngresado"></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
        <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
               @click="agregarDiscapacidad">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="listadoDiscapacidades" :headers="headerDiscapacidades"
                      no-data-text="No hay datos disponibles" class="elevation-1 header-table mb-10"
                      hide-default-footer>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarDiscapacidad(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <h3>Familiares (puedes agregar uno o más familiares)</h3>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-autocomplete label="Parentesco*" outlined v-model="parentescoFamiliarSeleccionado"
            :items="catalogoFamiliares"
            item-text="nombre" item-value="id" class="rounded-lg" required
            @blur="$v.parentescoFamiliarSeleccionado.$touch()"
            :error-messages="parentescoFamiliarSeleccionadoErrors"></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Primer nombre*" outlined v-model="primerNombreFamiliar"
            @input="notEmojis($event, 'primerNombreFamiliar', true)"
            v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'primerNombreFamiliar', false)"
            maxlength="50"
            counter
            @blur="$v.primerNombreFamiliar.$touch()"
            :error-messages="primerNombreFamiliarErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Segundo nombre" outlined v-model="segundoNombreFamiliar"
                        @input="notEmojis($event, 'segundoNombreFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'segundoNombreFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.segundoNombreFamiliar.$touch()"
                        :error-messages="segundoNombreFamiliarErrors"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
                @click="agregarFamiliar">
            Agregar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Tercer nombre" outlined v-model="tercerNombreFamiliar"
                        @input="notEmojis($event, 'tercerNombreFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'tercerNombreFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.tercerNombreFamiliar.$touch()"
                        :error-messages="tercerNombreFamiliarErrors"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Primer apellido*" outlined v-model="primerApellidoFamiliar"
                        @input="notEmojis($event, 'primerApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'primerApellidoFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.primerApellidoFamiliar.$touch()"
                        :error-messages="primerApellidoFamiliarErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Segundo apellido" outlined v-model="segundoApellidoFamiliar"
                        @input="notEmojis($event, 'segundoApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'segundoApellidoFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.segundoApellidoFamiliar.$touch()"
                        :error-messages="segundoApellidoFamiliarErrors"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Tercer apellido" outlined v-model="tercerApellidoFamiliar"
                        @input="notEmojis($event, 'tercerApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'tercerApellidoFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.tercerApellidoFamiliar.$touch()"
                        :error-messages="tercerApellidoFamiliarErrors"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Teléfono" outlined v-model="telefonoFamiliar" v-facade="'####-####'"
                        @blur="$v.telefonoFamiliar.$touch()"
                        :error-messages="telefonoFamiliarErrors"></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-autocomplete label="Parentesco*" outlined v-model="parentescoFamiliarSeleccionado"
                          :items="catalogoFamiliares"
                          item-text="nombre" item-value="id" class="rounded-lg" required
                          @blur="$v.parentescoFamiliarSeleccionado.$touch()"
                          :error-messages="parentescoFamiliarSeleccionadoErrors"></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Primer nombre*" outlined v-model="primerNombreFamiliar"
                        @input="notEmojis($event, 'primerNombreFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'primerNombreFamiliar', false)"
                        maxlength="50"
                        counter
                        @blur="$v.primerNombreFamiliar.$touch()"
                        :error-messages="primerNombreFamiliarErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Segundo nombre" outlined v-model="segundoNombreFamiliar"
                        @input="notEmojis($event, 'segundoNombreFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'segundoNombreFamiliar', false)"
                        maxlength="50"
                        counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Tercer nombre" outlined v-model="tercerNombreFamiliar"
                        @input="notEmojis($event, 'tercerNombreFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'tercerNombreFamiliar', false)"
                        maxlength="50"
                        counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Primer apellido*" outlined v-model="primerApellidoFamiliar"
                        @input="notEmojis($event, 'primerApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'primerApellidoFamiliar', false)"
                        maxlength="50"
                        counter></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Segundo apellido" outlined v-model="segundoApellidoFamiliar"
                        @input="notEmojis($event, 'segundoApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'segundoApellidoFamiliar', false)"
                        maxlength="50"
                        counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Tercer apellido" outlined v-model="tercerApellidoFamiliar"
                        @input="notEmojis($event, 'tercerApellidoFamiliar', true)"
                        v-on:keypress="soloTexto($event)" v-on:paste="notEmojis($event, 'tercerApellidoFamiliar', false)"
                        maxlength="50"
                        counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-text-field label="Teléfono" outlined v-model="telefonoFamiliar" v-facade="'####-####'"
                        @blur="$v.telefonoFamiliar.$touch()"
                        :error-messages="telefonoFamiliarErrors"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="3" md="3" xl="3">
          <v-btn rounded class="ml-5 text-capitalize" style="background-color: #1C1E4D; color: white;"
                @click="agregarFamiliar">
            Agregar
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-data-table :items="listadoFamiliares" :headers="headerFamiliares" no-data-text="No hay datos disponibles"
                      class="elevation-1 header-table mb-10" hide-default-footer>
          <template #[`item.nombres_familiar`]="{item}">
            {{ item.primer_nombre }} {{ item.segundo_nombre !== null ? item.segundo_nombre : '' }}
            {{ item.tercer_nombre !== null ? item.tercer_nombre : '' }}
          </template>
          <template #[`item.apellidos_familiar`]="{item}">
            {{ item.primer_apellido }} {{ item.segundo_apellido !== null ? item.segundo_apellido : '' }}
            {{ item.apellido_casada !== null ? item.apellido_casada : '' }}
          </template>
          <template #[`item.telefono`]="{ item }">
            {{ item.telefono ? item.telefono : '-' }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="eliminarFamiliar(item.id)" v-bind="attrs"
                       v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <template>
      <v-dialog v-model="showDialogDocumento" max-width="800px">
        <v-card class="pb-2">
          <embed
              width="100%"
              height="700"
              :src="verDocuemnto"
              :type="typeDoc"

          />
          <v-card-actions>
            <v-btn color="#22234a" class="my-text-button" dark rounded @click="showDialogDocumento = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import jwtDecode from 'jwt-decode';
import {required} from "vuelidate/lib/validators";

const noEmojisOrSpecialChars = (value) => {
  const noEmojiPattern = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
  const noSpecialCharPattern = /^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ\s]+$/;
  return noEmojiPattern.test(value) && noSpecialCharPattern.test(value);
};

const validtypeDocBoolean = (value, vm, typesExtention) => {
  if (value) {
    return typesExtention.some((ext) => ext === value.type)
  } else {
    return false
  }
}
export default {
  name: "Paso2",
  props: {
    ArrayPersona: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      //Estados para documentación
      showDialogDocumento: false,
      verDocuemnto: null,
      textAlertDoc: "",
      typeDoc: null,
      dialogFoto: false,
      dialogNombre: '',
      dialogId: null,
      fotoActualiza: null,
      tipoDocumentoSeleccionado: null,
      catalogoTipoDocumentos: [],
      catalogoDiscapacidades: [],
      catalogoFamiliares: [],
      numeroDocumentoIngresado: null,
      maxCaracteresNumeroDocumento: 0,
      fotoDocumentoIngresado: null,
      deshabilitarNumeroDocumento: true,
      nombreMedicamentoIngresado: null,
      especifiqueMedicamentoIngresado: null,
      nombreAlergiaIngresado: null,
      especifiqueAlergiaIngresado: null,
      nombreEnfermedadIngresado: null,
      especifiqueEnfermedadIngresada: null,
      discapacidadIngresada: null,
      especifiqueDiscapacidadIngresado: null,
      errorTipoDocumento: [],
      errorDocumentoAdjunto: [],
      errorNombreFamiliar: [],
      errorApellidoFamiliar: [],
      errorParentestoFamiliar: [],
      // errorMedicamento: [],
      // errorEspecifiqueMedicamento: [],
      // errorAlergia: [],
      // errorEspecifiqueAlergia: [],
      // errorEnfermedad: [],
      // errorEspecifiqueEnfermedad: [],
      // errorDiscapacidad: [],
      // errorEspecifiqueDiscapacidad: [],
      tipoAntecedenteMedico: [],

      headerDocumentos: [
        {text: 'Tipo de documento', align: 'center', sortable: true, value: 'tipo_documento'},
        {text: 'Número de documento', align: 'center', sortable: false, value: 'numero_documento'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      headerMedicamentos: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre'},
        {text: 'Descripción', align: 'center', sortable: false, value: 'descripcion'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      headerAlergias: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre'},
        {text: 'Descripción', align: 'center', sortable: false, value: 'descripcion'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      headerEnfermedades: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'nombre'},
        {text: 'Descripción', align: 'center', sortable: false, value: 'descripcion'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      headerDiscapacidades: [
        {text: 'Nombre', align: 'center', sortable: true, value: 'Discapacidad.nombre'},
        {text: 'Descripción', align: 'center', sortable: false, value: 'detalle_discapacidad'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      headerFamiliares: [
        {text: 'Nombres', align: 'center', sortable: true, value: 'nombres_familiar'},
        {text: 'Apellidos', align: 'center', sortable: false, value: 'apellidos_familiar'},
        {text: 'Teléfono', align: 'center', sortable: false, value: 'telefono'},
        {text: 'Parentesco', align: 'center', sortable: false, value: 'Parentesco.nombre'},
        {text: 'Acciones', align: 'center', sortable: false, value: 'acciones'},
      ],
      dataPersona: null,
      listadoDocumentos: [],
      listadoMedicamentos: [],
      listadoAlergias: [],
      listadoEnfermedades: [],
      listadoDiscapacidades: [],
      listadoFamiliares: [],
      primerNombreFamiliar: null,
      segundoNombreFamiliar: null,
      tercerNombreFamiliar: null,
      primerApellidoFamiliar: null,
      segundoApellidoFamiliar: null,
      tercerApellidoFamiliar: null,
      telefonoFamiliar: null,
      parentescoFamiliarSeleccionado: null,
      documento_mask: null,
      rules: {
        required: value => !!value || 'Favor ingresar el frente del documento.',
        telefono_requerido:
            value => !!value || 'Teléfono requerido',
        formato_telefono:
            value => {
              const pattern = /^[2,6-7]{1}[0-9]{3}[-]{1}[0-9]{4}$/
              return value && pattern.test(value) || 'El teléfono debe tener 8 números y comenzar con 2, 7 o 6'
            },
        contraseña: value => {
          const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/
          return pattern.test(value) || 'La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico'
        },
        minCaracteresDocumento: value => {
          return false
        }
      },
    };
  },
  validations: {
    tercerApellidoFamiliar: {noEmojisOrSpecialChars},
    segundoApellidoFamiliar: {noEmojisOrSpecialChars},
    tercerNombreFamiliar: {noEmojisOrSpecialChars},
    segundoNombreFamiliar: {noEmojisOrSpecialChars},
    especifiqueMedicamentoIngresado: {noEmojisOrSpecialChars},
    especifiqueAlergiaIngresado: {noEmojisOrSpecialChars},
    especifiqueDiscapacidadIngresado: {noEmojisOrSpecialChars},
    especifiqueEnfermedadIngresada: {noEmojisOrSpecialChars},
    discapacidadIngresada: {noEmojisOrSpecialChars},
    nombreEnfermedadIngresado: {noEmojisOrSpecialChars},
    nombreAlergiaIngresado: {noEmojisOrSpecialChars},
    nombreMedicamentoIngresado: {noEmojisOrSpecialChars},
    parentescoFamiliarSeleccionado: {required},
    primerNombreFamiliar: {required, noEmojisOrSpecialChars},
    primerApellidoFamiliar: {required, noEmojisOrSpecialChars},
    telefonoFamiliar: {
      formatoTelefono: function (value) {
        if (value) {
          const pattern = /^[2,6-7]{1}[0-9]{3}[-]{1}[0-9]{4}$/
          return value && pattern.test(value)
        } else {
          return true
        }
      }
    },
    tipoDocumentoSeleccionado: {
      required
    },
    numeroDocumentoIngresado: {
  required,
  formatoDocumento: function (value) {
    // Definir la expresión regular por defecto
    const mascaraPorDefecto = /^[0-9a-zA-Z-]+$/;

    // Verificar que `tipoDocumentoSeleccionado` no sea null o undefined
    if (this.tipoDocumentoSeleccionado) {      
        const documentoSeleccionado = this.catalogoTipoDocumentos.find(doc => doc.id === this.tipoDocumentoSeleccionado.id);
        
        // Verificar que se haya encontrado un documento
        if (documentoSeleccionado) {
            // Validar según la máscara
            if (documentoSeleccionado.mascara) {
                // Verificar si el valor tiene la longitud de la máscara
                if (value && value.length === documentoSeleccionado.mascara.length) {
                    return true;
                } else {
                    this.textAlertDoc = `Formato de ${documentoSeleccionado.nombre} inválido. Debe tener ${documentoSeleccionado.mascara.length} caracteres.`;
                    return false;
                }
            } else {
                // Validar usando la expresión regular por defecto
                if (value && value.length >= 3 && value.length <= 20 && mascaraPorDefecto.test(value)) {
                    return true;
                } else {
                    // Mensajes de error específicos
                    if (!value) {
                        this.textAlertDoc = `${documentoSeleccionado.nombre} es requerido.`;
                    } else if (value.length < 3) {
                        this.textAlertDoc = `${documentoSeleccionado.nombre} debe tener al menos 3 caracteres.`;
                    } else if (value.length > 20) {
                        this.textAlertDoc = `${documentoSeleccionado.nombre} debe tener como máximo 20 caracteres.`;
                    } else {
                        this.textAlertDoc = `${documentoSeleccionado.nombre} contiene caracteres no permitidos. Solo se permiten números, letras y guiones.`;
                    }
                    return false;
                }
            }
        } else {
            // En caso de no encontrar el documento
            this.textAlertDoc = 'Tipo de documento inválido. Por favor seleccione un tipo de documento válido.';
            return false;
        }
    } else {
        // En caso de que `tipoDocumentoSeleccionado` sea null o undefined
        this.textAlertDoc = 'Por favor seleccione un tipo de documento.';
        return false;
    }
}
},
    fotoDocumentoIngresado: {
      required,
      validtypeDocBoolean: function (value, vm) {
        if (value) {
          const ext = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf']
          return validtypeDocBoolean(value, vm, ext)
        } else {
          return true
        }
      },
      validSizeDoc: function (value) {
        if (value && value.size > 2097152) {
          return false
        } else {
          return true
        }
      }
    }
  },
  methods: {
    async validar_paso() {
      return true
    },
    normalizeAndSimplify(text) {
      if (text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase();
      }
    },
    validSelectedDoc(nombre) {
      return this.normalizeAndSimplify(nombre)
    },
    soloTexto(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(char)) return true;
      else e.preventDefault();
    },
    notEmojis(e, campo, isInput) {
      const valAcentos = /^[ÁÉÍÓÚáéíóúñÑ\s]+$/
      if (/[^ -~]/.test(e) && valAcentos.test(e)) {

        if (valAcentos.test(e)) {
          if (this.validateNotEmojis(e) && /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(e)) return true
          else false
        } else {
          this.$nextTick(() => {
            this[campo] = e.replace(/[^\x00-\x7F]/g, '')
          })
          return true
        }

      } else if (isInput) {
        if (this.validateNotEmojis(e) && /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(e)) return true
        else false
      } else {
        const value = e.clipboardData.getData('text')
        if (this.validateNotEmojis(value) && /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(value)) return true
        else e.preventDefault();
      }

    },
    async obtener_data() {
      return {}
    },
    eliminarFotoDocumento() {
      this.fotoDocumentoIngresado = null
    },
    async getAntecedenteMedico() {
      const response = await this.services.examenesMedicos.getTipoAntecedenteMedico()
      if (response?.data[0]?.id === 1) {
        const alergias = await this.services.examenesMedicos.getAntecedentesMedicos({
          id_tipo_antecedente_medico: 1,
          id_persona: this.dataPersona.id
        })
        if (alergias.status === 200)
          this.listadoAlergias = alergias.data

      }
      if (response?.data[1]?.id === 2) {
        const response2 = await this.services.examenesMedicos.getAntecedentesMedicos({
          id_tipo_antecedente_medico: 2,
          id_persona: this.dataPersona.id
        })
        if (response2.status === 200)
          this.listadoMedicamentos = response2.data
      }
      if (response?.data[2]?.id === 3) {
        const response3 = await this.services.examenesMedicos.getAntecedentesMedicos({
          id_tipo_antecedente_medico: 3,
          id_persona: this.dataPersona.id
        })
        if (response3.status === 200)
          this.listadoEnfermedades = response3.data
      }
    },
    async getDiscapacidadPorPersona() {
      const response = await this.services.examenesMedicos.getDiscapacidadPersona({
        id_persona: this.dataPersona.id
      })
      this.listadoDiscapacidades = response.data
    },
    async agregarDocumento() {
      this.$v.tipoDocumentoSeleccionado.$touch();
      this.$v.numeroDocumentoIngresado.$touch();
      this.$v.fotoDocumentoIngresado.$touch();

      if (this.$v.tipoDocumentoSeleccionado.$invalid || 
          this.$v.numeroDocumentoIngresado.$invalid || 
          this.$v.fotoDocumentoIngresado.$invalid) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Faltan campos requeridos"
        });
        return;
      }

      const formData = new FormData();
      formData.append("id_tipo_documento", this.tipoDocumentoSeleccionado.id);
      formData.append("numero_documento", this.numeroDocumentoIngresado);
      formData.append("archivo", this.fotoDocumentoIngresado);
      formData.append("id_persona", this.dataPersona.id);

      try {
        const response = await this.services.documento.agregarDocumentoPersona(formData);

        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento agregado con éxito"
          });
          await this.getDocumento();
          this.tipoDocumentoSeleccionado = null;
          this.$v.$reset();
          this.numeroDocumentoIngresado = null;
          this.fotoDocumentoIngresado = null;
        }
      } catch (e) {
        this.temporalAlert({
          show: true,
          type: "error",
          message:  e?.response?.data?.err?.description || response?.data?.message || "No se pudo agregar el documento"
        });
      }
    },
    async eliminarDocumento(id) {
      const response = await this.services.documento.eliminarDocumentoPersona(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.getDocumento()
      }
    },
    async eliminarDiscapacidad(id) {
      const response = await this.services.examenesMedicos.deleteDiscapacidadPersona(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message
        })
        await this.getDiscapacidadPorPersona()
      }
    },
    async eliminarAntecedente(item) {
      if (item.id_tipo_antecedente_medico == 1) {
        const response = await this.services.examenesMedicos.deleteAntecedenteMedico(item.id)
        if (response.status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Alergia eliminada con éxito"
          })
          await this.getAntecedenteMedico()
        }
      }
      if (item.id_tipo_antecedente_medico == 2) {
        const response2 = await this.services.examenesMedicos.deleteAntecedenteMedico(item.id)
        if (response2.status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Medicamento eliminado con éxito"
          })
          await this.getAntecedenteMedico()
        }
      }
      if (item.id_tipo_antecedente_medico == 3) {
        const response3 = await this.services.examenesMedicos.deleteAntecedenteMedico(item.id)
        if (response3.status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Enfermedad eliminada con éxito"
          })
          await this.getAntecedenteMedico()
        }
      }
    },
    tipoDocumentoValidacion() {
      let validez = false

      if (this.tipoDocumentoSeleccionado != null && this.tipoDocumentoSeleccionado != "") {
        validez = true
        this.errorTipoDocumento = []
      } else {
        validez = false
        this.errorTipoDocumento = ["Selecciona un tipo de documento"]
      }

      return validez
    },
    numeroDocumentoValidacion() {
      let errors = [];
      if (!this.$v.numeroDocumentoIngresado?.$dirty) return errors
      !this.$v.numeroDocumentoIngresado?.required && errors.push("Documento requerido")
      !this.$v.numeroDocumentoIngresado?.formatoDocumento && errors.push(this.textAlertDoc)
      return errors;
    },
    async eliminarFamiliar(id) {
      const response = await this.services.familiar.deleteFamiliar(id)
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Familiar eliminado con éxito"
        })
        await this.getFamiliares()
      }
    },
    documentoAdjuntoValidacion() {
      // let validez = false
      //
      // if (this.tipoDocumentoSeleccionado != null && this.tipoDocumentoSeleccionado != "") {
      //   if (this.tipoDocumentoSeleccionado == 3) {
      //     validez = true
      //     this.errorDocumentoAdjunto = []
      //   } else if (this.tipoDocumentoSeleccionado == 1) {
      //     if (this.fotoDocumentoIngresado != null && this.fotoDocumentoIngresado != "") {
      //       validez = true
      //       this.errorDocumentoAdjunto = []
      //
      //     } else {
      //       validez = false
      //       this.errorDocumentoAdjunto = ["Debe cargarse una archivo del documento"]
      //     }
      //   } else if (this.tipoDocumentoSeleccionado == 2) {
      //     if (this.fotoDocumentoIngresado != null && this.fotoDocumentoIngresado != "") {
      //       validez = true
      //       this.errorDocumentoAdjunto = []
      //     } else {
      //       validez = false
      //       this.errorDocumentoAdjunto = ["Debe cargarse una archivo del documento"]
      //     }
      //   }
      // } else {
      //   validez = false
      // }
      //
      // return validez

    },
    async getFamiliares() {
      const response = await this.services.familiar.getFamiliarPersona({
        id_persona: this.dataPersona.id
      })
      this.listadoFamiliares = response.data
    },
    async agregarFamiliar() {
      try {
        this.$v.primerNombreFamiliar.$touch();
        this.$v.segundoNombreFamiliar.$touch();
        this.$v.tercerNombreFamiliar.$touch();
        this.$v.primerApellidoFamiliar.$touch();
        this.$v.segundoApellidoFamiliar.$touch();
        this.$v.tercerApellidoFamiliar.$touch();
        this.$v.parentescoFamiliarSeleccionado.$touch();
        this.$v.telefonoFamiliar.$touch();
        const camposInvalidos = [
          this.$v.primerNombreFamiliar.$invalid,
          this.$v.primerApellidoFamiliar.$invalid,
          this.$v.parentescoFamiliarSeleccionado.$invalid,
          this.$v.telefonoFamiliar.$invalid,
          this.$v.tercerApellidoFamiliar.$invalid,
          this.$v.tercerNombreFamiliar.$invalid,
          this.$v.segundoApellidoFamiliar.$invalid,
          this.$v.segundoNombreFamiliar.$invalid,
        ];

        if (camposInvalidos.includes(true)) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message: "Completa correctamente los campos",
          });
          return;
        }
        const response = await this.services.familiar.postFamiliar({
          primer_nombre: this.primerNombreFamiliar,
          segundo_nombre: this.segundoNombreFamiliar,
          tercer_nombre: this.tercerNombreFamiliar,
          primer_apellido: this.primerApellidoFamiliar,
          segundo_apellido: this.segundoApellidoFamiliar,
          apellido_casada: this.tercerApellidoFamiliar,
          telefono: this.telefonoFamiliar,
          id_parentesco: this.parentescoFamiliarSeleccionado,
          id_persona: this.dataPersona.id,
        });

        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message,
          });
          this.$v.$reset();
          this.limpiarFamiliaInputs();
          await this.getFamiliares();
        }
      } catch (e) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: e.response?.data?.err?.message || "Ocurrió un error al agregar el familiar.",
        });
      }
    },
    limpiarFamiliaInputs() {
      this.primerNombreFamiliar = null
      this.segundoNombreFamiliar = null
      this.tercerNombreFamiliar = null
      this.primerApellidoFamiliar = null
      this.segundoApellidoFamiliar = null
      this.tercerApellidoFamiliar = null
      this.telefonoFamiliar = null
      this.parentescoFamiliarSeleccionado = null
    },
    // nombreFamiliarValidacion() {
    //   let validez = false
    //
    //   if (this.primerNombreFamiliar != null && this.primerNombreFamiliar != "") {
    //     validez = true
    //     this.errorNombreFamiliar = []
    //   } else {
    //     validez = false
    //     this.errorNombreFamiliar = ["Favor ingresar el nombre del familiar"]
    //   }
    //
    //   return validez
    // },
    // apellidoFamiliarValidacion() {
    //   let validez = false
    //
    //   if (this.primerApellidoFamiliar != null && this.primerApellidoFamiliar != "") {
    //     validez = true
    //     this.errorApellidoFamiliar = []
    //   } else {
    //     validez = false
    //     this.errorApellidoFamiliar = ["Favor ingresar el apellido del familiar"]
    //   }
    //
    //   return validez
    // },
    // parentescoFamiliarValidacion() {
    //   let validez = false
    //
    //   if (this.parentescoFamiliarSeleccionado != null && this.parentescoFamiliarSeleccionado != "") {
    //     validez = true
    //     this.errorParentestoFamiliar = []
    //   } else {
    //     validez = false
    //     this.errorParentestoFamiliar = ["Favor seleccionar un parentesco"]
    //   }
    //
    //   return validez
    // },
    async agregarMedicamento() {
      this.$v.nombreMedicamentoIngresado.$touch();
      this.$v.especifiqueMedicamentoIngresado.$touch();
      const esNombreMedicamentoValido = !this.$v.nombreMedicamentoIngresado.$invalid;
      const esEspecificacionMedicamentoValida = !this.$v.especifiqueMedicamentoIngresado.$invalid;

      if (!esNombreMedicamentoValido || !esEspecificacionMedicamentoValida) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos antes de continuar",
          type: "error",
        });
        return;
      }
      const validez_nombre_medicamento = this.nombreMedicamentoValidacion();
      const validez_especificacion_medicamento = this.especifiqueMedicamentoValidacion();
      if (!validez_nombre_medicamento || !validez_especificacion_medicamento) {
        this.temporalAlert({
          show: true,
          message: "Todos los campos son requeridos",
          type: "error",
        });
        return;
      }
      const response = await this.services.examenesMedicos.agregarAntecedenteMedico({
        id_persona: this.dataPersona.id,
        id_tipo_antecedente_medico: 2,
        nombre: this.nombreMedicamentoIngresado,
        descripcion: this.especifiqueMedicamentoIngresado,
      });
      if (response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Medicamento agregado con éxito",
        });
        await this.getAntecedenteMedico();
        this.nombreMedicamentoIngresado = null;
        this.especifiqueMedicamentoIngresado = null;
      }
    },
    nombreMedicamentoValidacion() {
      let validez = false

      if (this.nombreMedicamentoIngresado != null && this.nombreMedicamentoIngresado != "") {
        validez = true
        // this.errorMedicamento = []
      } else {
        validez = false
        // this.errorMedicamento = ["Favor ingresar el nombre de un medicamento"]
      }
      return validez
    },
    especifiqueMedicamentoValidacion() {
      let validez = false

      if (this.nombreMedicamentoIngresado != null && this.nombreMedicamentoIngresado != "") {
        if (this.especifiqueMedicamentoIngresado != null && this.especifiqueMedicamentoIngresado != "") {
          validez = true
          // this.errorEspecifiqueMedicamento = []
        } else {
          validez = false
          // this.errorEspecifiqueMedicamento = ["Favor especificar el medicamento"]
        }
      } else {
        validez = false
      }
      return validez
    },
    async agregarAlergia() {
      this.$v.nombreAlergiaIngresado.$touch();
      this.$v.especifiqueAlergiaIngresado.$touch();
      const esNombreAlergiaValido = !this.$v.nombreAlergiaIngresado.$invalid;
      const esEspecifiqueAlergiaValido = !this.$v.especifiqueAlergiaIngresado.$invalid;
      if (!esNombreAlergiaValido || !esEspecifiqueAlergiaValido) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos antes de continuar",
          type: "error",
        });
        return;
      }
      const validez_nombre_alergia = this.nombreAlergiaValidacion();
      const validez_especifique_alergia = this.especifiqueAlergiaValidacion();
      if (!validez_nombre_alergia || !validez_especifique_alergia) {
        this.temporalAlert({
          show: true,
          message: "Todos los campos son requeridos",
          type: "error",
        });
        return;
      }
      const response = await this.services.examenesMedicos.agregarAntecedenteMedico({
        id_persona: this.dataPersona.id,
        id_tipo_antecedente_medico: 1,
        nombre: this.nombreAlergiaIngresado,
        descripcion: this.especifiqueAlergiaIngresado,
      });

      if (response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Alergia agregada con éxito",
        });
        await this.getAntecedenteMedico();
        this.nombreAlergiaIngresado = null;
        this.especifiqueAlergiaIngresado = null;
      }
    },
    // async agregarAlergia() {
    //   this.$v.nombreAlergiaIngresado.$touch()
    //   this.$v.especifiqueAlergiaIngresado.$touch()
    //   let validez_nombre_alergia = this.nombreAlergiaValidacion()
    //   let validez_especifique_alergia = this.especifiqueAlergiaValidacion()
    //   if (!nombreAlergiaIngresado || !especifiqueAlergiaIngresado) {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Completa correctamente los campos antes de continuar",
    //       type: "error",
    //     });
    //     return;
    //   }

    //   if (validez_nombre_alergia == true && validez_especifique_alergia == true) {
    //     const response = await this.services.examenesMedicos.agregarAntecedenteMedico({
    //       id_persona: this.dataPersona.id,
    //       id_tipo_antecedente_medico: 1,
    //       nombre: this.nombreAlergiaIngresado,
    //       descripcion: this.especifiqueAlergiaIngresado
    //     })
    //     if (response.status == 201) {
    //       this.temporalAlert({
    //         show: true,
    //         type: "success",
    //         message: "Alergia agregada con éxito"
    //       })
    //       await this.getAntecedenteMedico()
    //       this.nombreAlergiaIngresado = null
    //       this.especifiqueAlergiaIngresado = null
    //     }
    //   } else {
    //     this.temporalAlert({
    //       show: true,
    //       message: "Favor completar todos los campos",
    //       type: "error",
    //     });
    //   }
    // },
    nombreAlergiaValidacion() {
      let validez = false

      if (this.nombreAlergiaIngresado != null && this.nombreAlergiaIngresado != "") {
        validez = true
        // this.errorAlergia = []
      } else {
        validez = false
        // this.errorAlergia = ["Favor ingresar el nombre de la alergía"]
      }
      return validez
    },
    especifiqueAlergiaValidacion() {
      let validez = false

      if (this.nombreAlergiaIngresado != null && this.nombreAlergiaIngresado != "") {
        if (this.especifiqueAlergiaIngresado != null && this.especifiqueAlergiaIngresado != "") {
          validez = true
          // this.errorEspecifiqueAlergia = []
        } else {
          validez = false
          // this.errorEspecifiqueAlergia = ["Favor especificar tu alergía"]
        }
      } else {
        validez = false
        // this.errorEspecifiqueAlergia = []
      }
      return validez
    },

    async agregarEnfermedad() {
      this.$v.nombreEnfermedadIngresado.$touch();
      this.$v.especifiqueEnfermedadIngresada.$touch();
      const esNombreEnfermedadValido = !this.$v.nombreEnfermedadIngresado.$invalid;
      const esEspecificacionEnfermedadValida = !this.$v.especifiqueEnfermedadIngresada.$invalid;
      if (!esNombreEnfermedadValido || !esEspecificacionEnfermedadValida) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos antes de continuar",
          type: "error",
        });
        return;
      }
      const validez_nombre_enfermedad = this.nombreEnfermedadValidacion();
      const validez_especificacion_enfermedad = this.especifiqueEnfermedadValidacion();
      if (!validez_nombre_enfermedad || !validez_especificacion_enfermedad) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos",
          type: "error",
        });
        return;
      }
      const response = await this.services.examenesMedicos.agregarAntecedenteMedico({
        id_persona: this.dataPersona.id,
        id_tipo_antecedente_medico: 3,
        nombre: this.nombreEnfermedadIngresado,
        descripcion: this.especifiqueEnfermedadIngresada,
      });

      if (response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Enfermedad agregada con éxito",
        });
        await this.getAntecedenteMedico();
        this.nombreEnfermedadIngresado = null;
        this.especifiqueEnfermedadIngresada = null;
      } else {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al agregar la enfermedad. Inténtalo de nuevo.",
          type: "error",
        });
      }
    },
    nombreEnfermedadValidacion() {
      let validez = false

      if (this.nombreEnfermedadIngresado != null && this.nombreEnfermedadIngresado != "") {
        validez = true
        // this.errorEnfermedad = []
      } else {
        validez = false
        // this.errorEnfermedad = ["Favor ingresar el nombre de la enfermedad"]
      }
      return validez
    },
    especifiqueEnfermedadValidacion() {
      let validez = false

      if (this.nombreEnfermedadIngresado != null && this.nombreEnfermedadIngresado != "") {
        if (this.especifiqueEnfermedadIngresada != null && this.especifiqueEnfermedadIngresada != "") {
          validez = true
          // this.errorEspecifiqueEnfermedad = []
        } else {
          validez = false
          // this.errorEspecifiqueEnfermedad = ["Favor especificar enfermedad"]
        }
      } else {
        validez = false
        // this.errorEspecifiqueEnfermedad = []
      }
      return validez
    },
    async agregarDiscapacidad() {
      this.$v.discapacidadIngresada.$touch();
      this.$v.especifiqueDiscapacidadIngresado.$touch();
      const esDiscapacidadValida = !this.$v.discapacidadIngresada.$invalid;
      const esEspecificacionValida = !this.$v.especifiqueDiscapacidadIngresado.$invalid;
      if (!esDiscapacidadValida || !esEspecificacionValida) {
        this.temporalAlert({
          show: true,
          message: "Completa correctamente los campos antes de continuar",
          type: "error",
        });
        return;
      }
      const validez_discapacidad = this.discapacidadIngresadaValidacion();
      const validez_especificacion_discapacidad = this.especifiqueDiscapacidadValidacion();
      if (!validez_discapacidad || !validez_especificacion_discapacidad) {
        this.temporalAlert({
          show: true,
          message: "Todos los campos son requeridos",
          type: "error",
        });
        return;
      }

      try {
        const response = await this.services.examenesMedicos.agregarDiscapacidad({
          id_persona: this.dataPersona.id,
          detalle_discapacidad: this.especifiqueDiscapacidadIngresado,
          id_discapacidad: this.discapacidadIngresada,
        });

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Discapacidad agregada con éxito",
          });
          await this.getDiscapacidadPorPersona();
          this.discapacidadIngresada = null;
          this.especifiqueDiscapacidadIngresado = null;
        }
      } catch (error) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "La persona ya tiene asignada esta discapacidad",
        });
      }
    },
    discapacidadIngresadaValidacion() {
      let validez = false

      if (this.discapacidadIngresada != null && this.discapacidadIngresada != "") {
        validez = true
        // this.errorDiscapacidad = []
      } else {
        validez = false
        // this.errorDiscapacidad = ["Favor selecciona una opción válida"]
      }
      return validez
    },
    especifiqueDiscapacidadValidacion() {
      let validez = false

      if (this.discapacidadIngresada != null && this.discapacidadIngresada != "") {
        if (this.especifiqueDiscapacidadIngresado != null && this.especifiqueDiscapacidadIngresado != "") {
          validez = true
          // this.errorEspecifiqueDiscapacidad = []
        } else {
          validez = false
          // this.errorEspecifiqueDiscapacidad = ["Favor especificar tu discapacidad"]
        }
      } else {
        validez = false
        // this.errorEspecifiqueDiscapacidad = []
      }
      return validez
    },
    async getTiposDocumentos() {
      const response = await this.services.documento.getTiposDocumentos()
      this.catalogoTipoDocumentos = response.data
      
    
    },
    async getParentesco() {
      const response = await this.services.datosPersona.getParentesco()
      this.catalogoFamiliares = response.data
    },
    async getDiscapacidades() {
      const response = await this.services.datosPersona.getDiscapacidad()
      this.catalogoDiscapacidades = response.data
    },
    async getDocumento() {
      const response = await this.services.documento.getDocumentoPersona({
        id_persona: this.dataPersona.id
      })
      
      this.listadoDocumentos = response.data
    },
    async showDocument(tipoConsulta, tipoDocumento) {

      const params = {
        tipo_consulta: tipoConsulta,
        tipo_documento: tipoDocumento,
        id_persona: this.dataPersona.id,
      }
      try {
        this.loading = true;
        let response = await this.services.datosPersona.getDocumentoPersona(params);
        if (response.status == 200) {


          //  this.getDoc(response?.data);

          this.showDialogDocumento = true;
          this.verDocuemnto = response?.data.archivo;
          this.typeDoc = response?.data.type;
        }
      } catch (e) {

      } finally {
        this.loading = false;
      }

    },
    async actualizarDocument(id) {

      if (this.fotoActualiza) {
        const formData = new FormData()
        formData.append("archivo", this.fotoActualiza)

        const params = {
          id: id,
          archivo: formData,
        }
        try {
          this.loading = true;
          let response = await this.services.documento.actualizarFotografiaDoc(params);
          if (response.status == 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Documento actualizado con éxito"
            });
            this.fotoActualiza = null;

          }
        } catch (e) {
          this.fotoActualiza = null;
        } finally {
          this.loading = false;
        }
        this.dialogFoto = false;
        await this.getDocumento()

      } else {

        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debes seleccionar una documento"
        });
      }

    },
    dialogOpen(id, nombre) {
      this.fotoActualiza = null;
      this.dialogNombre = nombre;
      this.dialogId = id;
      this.dialogFoto = true;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    documentoErrors() {
      const errors = [];
      if (!this.$v.tipoDocumentoSeleccionado?.$dirty) return errors;
      if (!this.$v.tipoDocumentoSeleccionado?.required) {
        errors.push("Selecciona un tipo de documento");
      }
      return errors;
    },
    NumeroDocumentoErrors() {
      let errors = [];
      if (!this.$v.numeroDocumentoIngresado?.$dirty) return errors
      !this.$v.numeroDocumentoIngresado?.required && errors.push("Documento requerido")
      !this.$v.numeroDocumentoIngresado?.formatoDocumento && errors.push(this.textAlertDoc)
      return errors;
    },
    fotoDocumentoErrors() {
      const errors = []
      if (!this.$v.fotoDocumentoIngresado?.$dirty) return errors
      !this.$v.fotoDocumentoIngresado?.required && errors.push("Por favor debes seleccionar un documento")
      !this.$v.fotoDocumentoIngresado?.validtypeDocBoolean && errors.push("Tipo de archivo inválido")
      !this.$v.fotoDocumentoIngresado?.validSizeDoc && errors.push('Tamaño maximo 2 MB')
      return errors
    },
    tipoDocumentoSeleccionadoErrors() {
      const errors = []
      if (!this.$v.tipoDocumentoSeleccionado?.$dirty) return errors
      !this.$v.tipoDocumentoSeleccionado?.required && errors.push("Por favor debes seleccionar un tipo documento")
      return errors
    },
    parentescoFamiliarSeleccionadoErrors() {
      const errors = []
      if (!this.$v.parentescoFamiliarSeleccionado?.$dirty) return errors
      !this.$v.parentescoFamiliarSeleccionado?.required && errors.push("Campo requerido")
      return errors
    },
    primerNombreFamiliarErrors() {
      const errors = []
      if (!this.$v.primerNombreFamiliar?.$dirty) return errors
      !this.$v.primerNombreFamiliar?.required && errors.push("Campo requerido")
      !this.$v.primerNombreFamiliar.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors
    },
    primerApellidoFamiliarErrors() {
      const errors = []
      if (!this.$v.primerApellidoFamiliar?.$dirty) return errors
      !this.$v.primerApellidoFamiliar?.required && errors.push("Campo requerido")
      !this.$v.primerApellidoFamiliar.noEmojisOrSpecialChars && errors.push("Caracteres inválidos");
      return errors
    },
    telefonoFamiliarErrors() {
      const errors = []
      if (!this.$v.telefonoFamiliar?.$dirty) return errors
      !this.$v.telefonoFamiliar?.formatoTelefono && errors.push("El teléfono debe tener 8 números y comenzar con 2, 7 o 6")

      return errors
    },
    errorMedicamento() {
      const errors = [];
      if (!this.$v.nombreMedicamentoIngresado.$dirty) return errors;
      if (!this.$v.nombreMedicamentoIngresado.noEmojisOrSpecialChars && this.$v.nombreMedicamentoIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorAlergia() {
      const errors = [];
      if (!this.$v.nombreAlergiaIngresado.$dirty) return errors;
      if (!this.$v.nombreAlergiaIngresado.noEmojisOrSpecialChars && this.$v.nombreAlergiaIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorEnfermedad() {
      const errors = [];
      if (!this.$v.nombreEnfermedadIngresado.$dirty) return errors;
      if (!this.$v.nombreEnfermedadIngresado.noEmojisOrSpecialChars && this.$v.nombreEnfermedadIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorDiscapacidad() {
      const errors = [];
      if (!this.$v.discapacidadIngresada.$dirty) return errors;
      if (!this.$v.discapacidadIngresada.noEmojisOrSpecialChars && this.$v.discapacidadIngresada.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorEspecifiqueEnfermedad() {
      const errors = [];
      if (!this.$v.especifiqueEnfermedadIngresada.$dirty) return errors;
      if (!this.$v.especifiqueEnfermedadIngresada.noEmojisOrSpecialChars && this.$v.especifiqueEnfermedadIngresada.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorEspecifiqueDiscapacidad() {
      const errors = [];
      if (!this.$v.especifiqueDiscapacidadIngresado.$dirty) return errors;
      if (!this.$v.especifiqueDiscapacidadIngresado.noEmojisOrSpecialChars && this.$v.especifiqueDiscapacidadIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorEspecifiqueAlergia() {
      const errors = [];
      if (!this.$v.especifiqueAlergiaIngresado.$dirty) return errors;
      if (!this.$v.especifiqueAlergiaIngresado.noEmojisOrSpecialChars && this.$v.especifiqueAlergiaIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    errorEspecifiqueMedicamento() {
      const errors = [];
      if (!this.$v.especifiqueMedicamentoIngresado.$dirty) return errors;
      if (!this.$v.especifiqueMedicamentoIngresado.noEmojisOrSpecialChars && this.$v.especifiqueMedicamentoIngresado.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    segundoNombreFamiliarErrors() {
      const errors = [];
      if (!this.$v.segundoNombreFamiliar.$dirty) return errors;
      if (!this.$v.segundoNombreFamiliar.noEmojisOrSpecialChars && this.$v.segundoNombreFamiliar.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    tercerNombreFamiliarErrors() {
      const errors = [];
      if (!this.$v.tercerNombreFamiliar.$dirty) return errors;
      if (!this.$v.tercerNombreFamiliar.noEmojisOrSpecialChars && this.$v.tercerNombreFamiliar.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    segundoApellidoFamiliarErrors() {
      const errors = [];
      if (!this.$v.segundoApellidoFamiliar.$dirty) return errors;
      if (!this.$v.segundoApellidoFamiliar.noEmojisOrSpecialChars && this.$v.segundoApellidoFamiliar.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    tercerApellidoFamiliarErrors() {
      const errors = [];
      if (!this.$v.tercerApellidoFamiliar.$dirty) return errors;
      if (!this.$v.tercerApellidoFamiliar.noEmojisOrSpecialChars && this.$v.tercerApellidoFamiliar.$model.trim() !== '') {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
  },
  watch: {
    tipoDocumentoSeleccionado: {
  handler(e) {
    this.numeroDocumentoIngresado = ''; 
    if (e != null  ) {
       if (e.mascara != null) {
        this.documento_mask = e.mascara;
        this.maxCaracteresNumeroDocumento = e.mascara.length;
        this.deshabilitarNumeroDocumento = false;
       } else { 
        const mascaraPorDefecto = /^[0-9a-zA-Z-]{3,20}$/;
        this.documento_mask = mascaraPorDefecto;
        this.maxCaracteresNumeroDocumento = 20;
        this.deshabilitarNumeroDocumento = false;
       }
     
    } else {
      this.deshabilitarNumeroDocumento = true;
    }

  }
},
  },
  created() {
    this.getTiposDocumentos()
    this.getParentesco()
    this.getDiscapacidades()
    const userDecode = jwtDecode(localStorage.getItem('token') || this.userInfo.token)
    this.dataPersona = userDecode.persona
    this.getDocumento()
    this.getAntecedenteMedico()
    this.getDiscapacidadPorPersona()
    this.getFamiliares()
  }
}
</script>

<style></style>
